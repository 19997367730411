import Banner from "@/components/Cliente/Banner/Banner.vue";
import Pergunta from "./Pergunta/Pergunta.vue";
import {
	mask
} from 'vue-the-mask'

export default {
	name: "Perguntas-e-respostas",
	directives: {
		mask
	},
	components: {
		Banner,
		Pergunta
	},
	data() {
		return {
			arBreadcrumbs: [],
			arPerguntas: [],
			blLoading: true,
			blNaoEcontrado: false,
			idPerguntaAberta: 0,
			intPesquisa: null,
			obAssunto: null,
			obRelacionada: null,
			obPergunta: null,
			snackbar: false,
		};
	},
	created() {
		this.listar();
		this.iniciarBreadcrumbs();
	},
	methods: {
		listar() {
			this.$root.$api
				.getUrlCompleta(
					"https://www.econeteditora.com.br/irpf/perguntas_respostas.php?ano=2021"
				)
				.then(response => {
					this.arPerguntas = response;

					if (this.$route.params.parametro) {
						this.obRelacionada = null;
						this.obAssunto = null;
						this.blNaoEcontrado = false;
						this.filtrarPergunta(this.$route.params.parametro, false, true);
					}

					this.blLoading = false;
				});
		},
		iniciarBreadcrumbs() {
			this.arBreadcrumbs = [{
					text: "Início",
					href: "/"
				},
				{
					text: "Perguntas e Respostas"
				}
			]
		},
		selecionarAssunto(obAssunto, key) {
			this.obAssunto = this.lodash.cloneDeep(obAssunto);
			this.obAssunto.key = key;
			this.obAssunto.abertos = [];

			this.arBreadcrumbs.push({
				text: obAssunto.nome.toLowerCase().replace('<br />', '').replace('<br>','').replace('<br/>', '')
			})

			this.panel = null;
		},
		voltarParaAssuntos() {
			if (this.obAssunto.blTema) {
				this.selecionarAssunto(this.arPerguntas[this.obAssunto.key], this.obAssunto.key);
			} else {
				this.obAssunto = null;
				this.iniciarBreadcrumbs();
			}
		},
		selecionarTema(parametro) {
			const arParametros = parametro.split('@');

			this.selecionarAssunto(this.arPerguntas[arParametros[0]], arParametros[0]);

			let obTema = null,
				intContador = 0;

			Object.keys(this.arPerguntas[this.obAssunto.key].perguntas).forEach(keyTema => {
				if (intContador == arParametros[1]) {
					obTema = this.arPerguntas[this.obAssunto.key].perguntas[keyTema];
				}

				intContador++;
			})

			this.obAssunto.perguntas = [obTema];
			this.obAssunto.abertos = 0;
			this.obAssunto.blTema = true;
		},
		selecionarPergunta(obPergunta) {
			if (this.idPerguntaAberta == obPergunta.id) {
				this.arBreadcrumbs.pop();
				this.idPerguntaAberta = 0;
			} else {
				if (this.arBreadcrumbs.length == 4) {
					this.arBreadcrumbs.pop();
				}

				this.idPerguntaAberta = obPergunta.id;
				this.arBreadcrumbs.push({
					text: obPergunta.titulo.replace('<br />', '').replace('<br>','').replace('<br/>', '')
				})
			}
		},
		selecionaRelacionada(idRelacionada) {
			this.obRelacionada = null;

			this.filtrarPergunta(idRelacionada, false, false);
		},
		sairRelacionada() {
			this.obRelacionada = null;
			this.arBreadcrumbs.pop();
		},
		filtrarPergunta(parametro, blPesquisa, blAssunto) {
			if (!isNaN(parametro)) {
				Object.keys(this.arPerguntas).map(key => {
					Object.keys(this.arPerguntas[key].perguntas).forEach(keyTema => {
						this.arPerguntas[key].perguntas[keyTema].perguntas.forEach(obPergunta => {
							if ((Number(parametro) == Number(obPergunta.numero) && blPesquisa) ||
								(Number(parametro) == Number(obPergunta.id) && !blPesquisa)) {

								if (blAssunto) {
									this.obAssunto = this.arPerguntas[key];
									this.obAssunto.key = key;

									this.arBreadcrumbs.push({
										text: this.obAssunto.nome.toLowerCase().replace('<br />', '').replace('<br>','').replace('<br/>', '')
									})
								}

								this.obRelacionada = {
									abertos: [0],
									pergunta: obPergunta,
									assunto: this.arPerguntas[key].nome,
									keyAssunto: key,
								};

								this.arBreadcrumbs.push({
									text: obPergunta.titulo.replace('<br />', '').replace('<br>','').replace('<br/>', '')
								})
							}
						})
					})
				})
			} else {
				if (parametro.indexOf('@') >= 0) {
					this.selecionarTema(parametro);
				} else {
					this.selecionarAssunto(this.arPerguntas[parametro], parametro);
				}
			}
		},
		pesquisar() {
			this.obRelacionada = null;
			this.obAssunto = null;
			this.blNaoEcontrado = false;
			this.iniciarBreadcrumbs();

			if (this.intPesquisa > 0) {
				this.filtrarPergunta(this.intPesquisa, true, true);

				if (!this.obRelacionada) {
					this.blNaoEcontrado = true;
				}
			}
		},
		copiarLink(parametro) {
			let testingCodeToCopy = document.querySelector('#hiddenLink')

			let route = this.$router.resolve({
				name: "perguntas-e-respostas",
				params: {
					parametro: parametro
				}
			});

			testingCodeToCopy.value = document.location.origin + route.href;

			testingCodeToCopy.setAttribute('type', 'text')
			testingCodeToCopy.select()
			var successful = document.execCommand('copy');

			testingCodeToCopy.setAttribute('type', 'hidden')
			window.getSelection().removeAllRanges()

			this.snackbar = true;
		},
		copiarLinkTema(indexTema) {
			let intIndex, intContador = 0;

			Object.keys(this.arPerguntas[this.obAssunto.key].perguntas).forEach(keyTema => {
				if (keyTema == indexTema) {
					intIndex = intContador
				}

				intContador++;
			})

			this.copiarLink(this.obAssunto.key + "@" + intIndex);
		},
	}
};