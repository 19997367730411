export default {
    name: "Banner",
    props: {
        strTitulo: "",
        home: {
            type:Boolean,
            default:false
        },
        arrayEvents: null,
    },
    data() {
        return {
            model: 0,
            bgs: [{id : -1},{id : -2}],
            obEvento: null,
			obEventos: [],
            date: new Date().toISOString().substr(0, 10),
            obrigacaoAtiva: null,
            backHistory: [],
			dialog: false,
            dataClicked: undefined
        };
    },
    computed: {
        eventos() {
			if (this.arrayEvents == null) {
				return [];			}
			return this.arrayEvents.map(evento => {
				return evento.data;
			});
		}
    },
    created() {
        this.$root.$api.get("home/carrousels").then(response => {
            this.bgs = this.bgs.concat(response.retorno);
        });
    },
    methods: {
        atualizarDias() {
			let data_limite = new Date("2021-05-31");
			let data_atual = new Date();

			const diferenca = Math.abs(
				data_atual.getTime() - data_limite.getTime()
			);

			const dias = Math.ceil(diferenca / (1000 * 60 * 60 * 24));

			return dias;
		},
        showObrigacaoDialog(obrigacao) {
			this.obrigacaoAtiva = obrigacao;
		},
        getData(d) {
            // console.log(d);
			// return d.split("-")
			// 		.reverse()
			// 		.join("/");
		},
        fechaDialog() {
			this.dialog = false
			this.backHistory = [];
			this.obrigacaoAtiva = undefined;
		},
        backDia() {
			let dia = this.backHistory.pop();
			this.eventosDataCalandario(dia);
		},
        eventosDataCalandario(data, hasBack = false) {
			this.obEvento = this.arrayEvents.find(
				evento => data == evento.data
			);
			this.obEventos = this.arrayEvents.filter(
				evento => data == evento.data
			);
			// console.log(this.arrayEvents, data);
			if (typeof this.obEvento !== "undefined") {
				this.dataClicked = data.split("-")
					.reverse()
					.join("/");
				this.dialog = true;

				if(hasBack) {
					this.backHistory.push(data);
				}
			}
		},
        obterCorEvento(data) {
			if (!this.arrayEvents || !data) {
				return null;
			}

			if (
				typeof this.arrayEvents.find(evento => data == evento.data) !=
				"undefined" &&
				this.arrayEvents.find(evento => data == evento.data)
				.id_tipo_evento == 1
			) {
				return "#41B883";
			} else if (
				typeof this.arrayEvents.find(evento => data == evento.data) !=
				"undefined" &&
				this.arrayEvents.find(evento => data == evento.data)
				.id_tipo_evento == 2
			) {
				return "#ff8200";
			}

			return "#98BFFF";
		}
    },
};
